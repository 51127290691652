<template>
	<div class="pui-form">
		<pui-form-header
			v-if="modelLoaded"
			:showHeader="!isCreatingElement">
			<documentosaduaneroequipamiento-form-header
				:modelPk="modelPk"
			></documentosaduaneroequipamiento-form-header>
		</pui-form-header>
		<v-form class="mb-4 pb-4" ref="form" v-model="valid" lazy-validation @submit.prevent v-if="modelLoaded">
			<v-layout row wrap class="pui-form-layout">

			</v-layout>

			<!-- Page Footer -->
			<pui-form-footer v-if="!isModalDialog">
				<pui-form-footer-btns
					:formDisabled="formDisabled"
					:saveDisabled="saving"
					:saveAndNew="saveAndNew"
					:saveAndUpdate="saveAndUpdate"
					:save="save"
					:back="back"
				></pui-form-footer-btns>
			</pui-form-footer>
		</v-form>
		<pui-form-loading
			v-else
		></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';
import documentosaduaneroequipamientoActions from './DocumentosaduaneroequipamientoActions';

export default {
	name: 'documentosaduaneroequipamiento-form',
	mixins: [PuiFormMethodsMixin],
	components: {
	},
	data() {
		return {
			modelName: 'documentosaduaneroequipamiento'
			, actions: documentosaduaneroequipamientoActions.formActions
		};
	},
	methods: {
		afterGetData() {
			// Do something after get data from server
		}
	},
	computed: {
	},
	created() {
	}
}
</script>
